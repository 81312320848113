import React from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import CodeIcon from "@material-ui/icons/Code";
import { c_047, c_048, c_049, c_050 } from "../../../resources/ClassNames";
import { v_01 } from "../../../resources/Values";
import { src_019, src_020 } from "../../../resources/Sources";
import { str_028, str_029 } from "../../../resources/Strings";
import "./../../../styles/components/portfolio/subcomponents/GithubLeetCode.css";

function GithubLeetCode() {
  return (
    <div className={c_047}>
      <a className={c_048} href={src_019}>
        <center>
          <GitHubIcon fontSize={v_01} className={c_049} />
        </center>
        <div className={c_050}>{str_028}</div>
      </a>
      <a className={c_048} href={src_020}>
        <center>
          <CodeIcon fontSize={v_01} className={c_049} />
        </center>
        <div className={c_050}>{str_029}</div>
      </a>
    </div>
  );
}

export default GithubLeetCode;
