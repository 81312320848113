import React from "react";
import CertsBody from "../components/certs/CertsBody";
import HeaderBody from "../components/header/HeaderBody";
import { c_004 } from "../resources/ClassNames";
import "./../styles/pages/Certifications.css";

function Certifications() {
  return (
    <div className={c_004}>
      <HeaderBody />
      <CertsBody />
    </div>
  );
}

export default Certifications;
