import React from "react";
import { c_010, c_011, c_012 } from "../../../resources/ClassNames";
import "./../../../styles/components/about/AboutSection.css";

function AboutSection({ title, info }) {
  return (
    <div className={c_010}>
      <div className={c_011}>{title}</div>
      <div className={c_012}>{info}</div>
    </div>
  );
}

export default AboutSection;
