import React from "react";
import { c_015, c_016, c_017 } from "../../../resources/ClassNames";
import "./../../../styles/components/certs/subcomponents/Cert.css";

function Cert({ src, picSrc, picAlt }) {
  return (
    <div className={c_015}>
      <a href={src} className={c_016}>
        <img className={c_017} src={picSrc} alt={picAlt} />
      </a>
    </div>
  );
}

export default Cert;
