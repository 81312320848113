import React from "react";
import Loader from "react-loader-spinner";
import { c_061 } from "../../resources/ClassNames";
import { v_09, v_10, v_11 } from "../../resources/Values";
import "./../../styles/components/utils/LoadingSpinner.css";

function LoadingSpinner({ visibility }) {
  return (
    <div className={c_061}>
      <Loader
        type={v_09}
        color={v_10}
        height={v_11}
        width={v_11}
        visible={visibility}
      />
    </div>
  );
}

export default LoadingSpinner;
