import React from "react";
import "./../../../styles/components/portfolio/subcomponents/Apps.css";
import { c_054 } from "../../../resources/ClassNames";

function Apps() {
  return (
    <div className={"apps__row"}>
      <div className={"apps__item"}>
        <a
          href={"https://github.com/irish1uck/Do-Thot"}
          className={"apps__itemLink"}
        >
          <div className={c_054}>{"react native to do list app"}</div>
          <img
            className={"apps__itemThumbnail"}
            src={"https://i.imgur.com/eBInc7M.png"}
            alt={"Home screen of Do-Thot"}
          />
        </a>
      </div>
      <div className={"apps__item"}>
        <a
          href={"https://github.com/irish1uck/ToDoListApp"}
          className={"apps__itemLink"}
        >
          <div className={c_054}>{"kotlin to do list app"}</div>
          <img
            className={"apps__itemThumbnail"}
            src={"https://i.imgur.com/G69mHhZ.png"}
            alt={"Home screen of Go-do-it"}
          />
        </a>
      </div>
    </div>
  );
}

export default Apps;
