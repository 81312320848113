import React from "react";
import ResumeBody from "../components/resume/ResumeBody";
import HeaderBody from "../components/header/HeaderBody";
import { c_008 } from "../resources/ClassNames";
import "./../styles/pages/Resume.css";

function Resume() {
  return (
    <div className={c_008}>
      <HeaderBody />
      <ResumeBody />
    </div>
  );
}

export default Resume;
