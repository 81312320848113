export const str_001 = "about me";
export const str_002 = "early life";
export const str_003 = `I grew up in Natick, MA and in high school I played 
    lacrosse, wrestled, and I was on the golf team.`;
export const str_004 = "education";
export const str_005 = `I graduated from Doherty Memorial High School in 2005. 
    I studied Economics at The University of Massachusetts, in Lowell, MA 
    before transfering to The University of Massachusetts in Amherst, MA 
    switching my major to Theatre.`;
export const str_006 = "moved to california";
export const str_007 = `In November of 2011, I moved to California, 
    specifically San Clemente where I have family, in pursuit of my acting 
    career. In 2012, I moved to Dana Point and then in May of 2016, I moved 
    to Los Angeles.`;
export const str_008 = "working in the restaurant industry";
export const str_009 = `I started working in the restaurant industry in 2003 
    at a country club in Boylston, MA, as a busser at age 16. I worked there 
    for 8 and half years, being promoted to valet and then server and
    bartender. Since then I have worked at many more restaurants as a
    server, a bartender, and as a manager at a few. Throughout my years
    working in the restaurant industry, I have developed many "soft
    skills" such as teamwork, communication, leadership, stress
    management, time management, and many more, I feel will benefit me
    in any industry. Prior to quarantine I was a server at a steakhouse,
    while pursuing acting and learning computer programming in my free
    time.`;
export const str_010 = "computer programming certifications";
export const str_011 = "contact me";
export const str_012 = "linkedin";
export const str_013 = "instagram";
export const str_014 = "facebook";
export const str_015 = "email";
export const str_016 = "github";
export const str_017 = "leetcode";
export const str_018 = "freecodecamp";
export const str_019 = "ar";
export const str_020 = "portfolio";
export const str_021 = "resume";
export const str_022 = "certs";
export const str_023 = "about";
export const str_024 = "contact";
export const str_025 = "adam reeves";
export const str_026 = "software engineer";
export const str_027 = "web & application development";
export const str_028 = "github profile";
export const str_029 = "leetcode profile";
export const str_030 = "the weekend society (business website)";
export const str_031 = "crystal oak healing (business website)";
export const str_032 = "covid-19 tracker";
export const str_033 = "instagram clone demo";
export const str_034 = "amazon clone demo";
export const str_035 = "netflix clone demo";
export const str_036 = "résumé";
