import React from "react";
import ReorderIcon from "@material-ui/icons/Reorder";
import { c_033, c_034, c_035 } from "../../../resources/ClassNames";
import { v_01 } from "../../../resources/Values";
import "./../../../styles/components/header/subcomponents/MobileMenuButton.css";

function MobileMenuButton({ menuButtonFunc }) {
  return (
    <div className={c_033}>
      <button className={c_034} onClick={menuButtonFunc}>
        <ReorderIcon fontSize={v_01} className={c_035} />
      </button>
    </div>
  );
}

export default MobileMenuButton;
