import React, { useState } from "react";
import { c_023, c_024, c_025, c_026 } from "../../resources/ClassNames";
import HomeButton from "./subcomponents/HomeButton";
import MobileMenu from "./subcomponents/MobileMenu";
import MobileMenuButton from "./subcomponents/MobileMenuButton";
import WebMenu from "./subcomponents/WebMenu";
import { v_13, v_14 } from "../../resources/Values";
import "./../../styles/components/header/HeaderBody.css";

function HeaderBody() {
  const [count, setCount] = useState(v_13);

  const toggleMenu = () => {
    if (count === v_13) {
      setCount(v_14);
    } else {
      setCount(v_13);
    }
  };

  return (
    <nav className={c_023}>
      <div className={c_024}>
        <HomeButton />
        <div className={c_025}>
          <WebMenu />
          <MobileMenuButton menuButtonFunc={toggleMenu} />
        </div>
      </div>
      <div className={c_026}>
        <MobileMenu count={count} />
      </div>
    </nav>
  );
}

export default HeaderBody;
