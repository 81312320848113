import React, { useState, useEffect } from "react";
import { c_046 } from "../../resources/ClassNames";
import { str_020 } from "../../resources/Strings";
import { v_06, v_07, v_08 } from "../../resources/Values";
import LoadingSpinner from "../utils/LoadingSpinner";
import PageTitle from "../utils/PageTitle";
import Projects from "./subcomponents/Projects";
import "./../../styles/components/portfolio/PortfolioBody.css";
import Apps from "./subcomponents/Apps";

function PortfoloioBody() {
  const [loaderVisible, setLoaderVisible] = useState(v_06);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(v_07);
    }, v_08);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loaderVisible ? (
        <LoadingSpinner visibility={loaderVisible} />
      ) : (
        <div className={c_046}>
          <PageTitle title={str_020} />
          <Apps />
          <Projects />
        </div>
      )}
    </div>
  );
}

export default PortfoloioBody;
