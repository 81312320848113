import React from "react";
import ContactBody from "../components/contact/ContactBody";
import HeaderBody from "../components/header/HeaderBody";
import { c_005 } from "../resources/ClassNames";
import "./../styles/pages/Contact.css";

function Contact() {
  return (
    <div className={c_005}>
      <HeaderBody />
      <ContactBody />
    </div>
  );
}

export default Contact;
