import { v_02, v_03, v_04, v_05 } from "../../../resources/Values";

export const mobileMenuOn = {
  display: v_02,
  flexWrap: v_03,
  alignItems: v_04,
  justifyContent: v_04,
};

export const mobileMenuOff = {
  display: v_05,
};
