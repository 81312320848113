import React from "react";
import HomeBody from "../components/home/HomeBody";
import HeaderBody from "../components/header/HeaderBody";
import { c_006 } from "../resources/ClassNames";
import "./../styles/pages/Home.css";

function Home() {
  return (
    <div className={c_006}>
      <HeaderBody />
      <HomeBody />
    </div>
  );
}

export default Home;
