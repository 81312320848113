import React, { useState, useEffect } from "react";
import PageTitle from "../utils/PageTitle";
import ResumeThumbnail from "./subcomponents/ResumeThumbnail";
import LoadingSpinner from "../utils/LoadingSpinner";
import { v_06, v_07, v_08 } from "../../resources/Values";
import { c_056 } from "../../resources/ClassNames";
import { str_036 } from "../../resources/Strings";
import "./../../styles/components/resume/ResumeBody.css";

function ResumeBody() {
  const [loaderVisible, setLoaderVisible] = useState(v_06);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(v_07);
    }, v_08);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loaderVisible ? (
        <LoadingSpinner visibility={loaderVisible} />
      ) : (
        <div className={c_056}>
          <PageTitle title={str_036} />
          <ResumeThumbnail />
        </div>
      )}
    </div>
  );
}

export default ResumeBody;
