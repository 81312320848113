import React from "react";
import AboutBody from "../components/about/AboutBody";
import HeaderBody from "../components/header/HeaderBody";
import { c_003 } from "../resources/ClassNames";
import "./../styles/pages/About.css";

function About() {
  return (
    <div className={c_003}>
      <HeaderBody />
      <AboutBody />
    </div>
  );
}

export default About;
