export const c_001 = "root";
export const c_002 = "app";
export const c_003 = "about__page";
export const c_004 = "certifications__page";
export const c_005 = "contact__page";
export const c_006 = "home__page";
export const c_007 = "portfolio__page";
export const c_008 = "resume__page";
export const c_009 = "aboutMe__container";
export const c_010 = "aboutMe__section";
export const c_011 = "aboutMe__sectionTitle";
export const c_012 = "aboutMe__sectionInfo";
export const c_013 = "certifications__container";
export const c_014 = "certifications__row";
export const c_015 = "certification";
export const c_016 = "certification__link";
export const c_017 = "certification__thumbnail";
export const c_018 = "contact__container";
export const c_019 = "contact__socialMediaContainer";
export const c_020 = "contact__socialLink";
export const c_021 = "icon";
export const c_022 = "contact__linkTitle";
export const c_023 = "header";
export const c_024 = "header__container";
export const c_025 = "header__links";
export const c_026 = "mobileMenu";
export const c_027 = "header__linkHome";
export const c_028 = "header__buttonHome";
export const c_029 = "header__buttonHomeText";
export const c_030 = "header__mobileLink";
export const c_031 = "mobileMenu__button";
export const c_032 = "header__mobileButtonText";
export const c_033 = "header__mobileMenuButtonContainer";
export const c_034 = "header__mobileMenuButton";
export const c_035 = "header__mobileMenuButtonIcon";
export const c_036 = "header__webLinks";
export const c_037 = "header__link";
export const c_038 = "header__button";
export const c_039 = "header__buttonText";
export const c_040 = "home__container";
export const c_041 = "home__mainCenter";
export const c_042 = "home__title";
export const c_043 = "home__lineBreak";
export const c_044 = "home__subtitle";
export const c_045 = "home__subtitleDetails";
export const c_046 = "portfolio__container";
export const c_047 = "portfolio__row";
export const c_048 = "github__link";
export const c_049 = "portfolio__icon";
export const c_050 = "project__title";
export const c_051 = "portfolio__projects";
export const c_052 = "project";
export const c_053 = "project__link";
export const c_054 = "project__title";
export const c_055 = "project__thumbnail";
export const c_056 = "resume__container";
export const c_057 = "resumePic__page";
export const c_058 = "resumePic__image";
export const c_059 = "resume__link";
export const c_060 = "resume__thumbnail";
export const c_061 = "loadingSpinner";
export const c_062 = "pageTitle";
export const c_063 = "portfolio_apps";
