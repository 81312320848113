import React from "react";
import { alt_013 } from "../../../resources/Alternates";
import { c_057, c_058 } from "../../../resources/ClassNames";
import { src_034 } from "../../../resources/Sources";
import "./../../../styles/components/resume/subcomponents/ResumePic.css";

function ResumePic() {
  return (
    <div className={c_057}>
      <img className={c_058} src={src_034} alt={alt_013} />
    </div>
  );
}

export default ResumePic;
