import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { c_020, c_021, c_022 } from "../../../resources/ClassNames";
import { v_01 } from "../../../resources/Values";
import "./../../../styles/components/contact/subcomponents/SocialMediaLink.css";

function SocialLink({ IconLogo, source, title }) {
  return (
    <a href={source} className={c_020}>
      <IconButton>
        <IconLogo fontSize={v_01} className={c_021} />
        <h3 className={c_022}>{title}</h3>
      </IconButton>
    </a>
  );
}

export default SocialLink;
