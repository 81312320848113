import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./app/App";
import { c_001 } from "./resources/ClassNames";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(c_001)
);
