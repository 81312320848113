import React from "react";
import SocialLink from "./SocialLink";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import CodeIcon from "@material-ui/icons/Code";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import { c_019 } from "../../../resources/ClassNames";
import {
  src_015,
  src_016,
  src_017,
  src_018,
  src_019,
  src_020,
  src_021,
} from "../../../resources/Sources";
import {
  str_012,
  str_013,
  str_014,
  str_015,
  str_016,
  str_017,
  str_018,
} from "../../../resources/Strings";
import "./../../../styles/components/contact/subcomponents/SocialMedia.css";

function SocialMedia() {
  return (
    <div className={c_019}>
      <SocialLink IconLogo={LinkedInIcon} source={src_015} title={str_012} />
      <SocialLink IconLogo={InstagramIcon} source={src_016} title={str_013} />
      <SocialLink IconLogo={FacebookIcon} source={src_017} title={str_014} />
      <SocialLink IconLogo={EmailIcon} source={src_018} title={str_015} />
      <SocialLink IconLogo={GitHubIcon} source={src_019} title={str_016} />
      <SocialLink IconLogo={CodeIcon} source={src_020} title={str_017} />
      <SocialLink
        IconLogo={DeveloperModeIcon}
        source={src_021}
        title={str_018}
      />
    </div>
  );
}

export default SocialMedia;
