import React from "react";
import { Link } from "react-router-dom";
import { c_030, c_031, c_032 } from "../../../resources/ClassNames";
import { p_01, p_02, p_03, p_05, p_06 } from "../../../resources/Paths";
import {
  str_020,
  str_021,
  str_022,
  str_023,
  str_024,
} from "../../../resources/Strings";
import { mobileMenuOff, mobileMenuOn } from "./HeaderUtils";

function MobileMenu({ count }) {
  return (
    <div style={count === 0 ? mobileMenuOff : mobileMenuOn}>
      <Link to={p_05} className={c_030}>
        <button className={c_031}>
          <div className={c_032}>{str_020}</div>
        </button>
      </Link>
      <Link to={p_06} className={c_030}>
        <button className={c_031}>
          <div className={c_032}>{str_021}</div>
        </button>
      </Link>
      <Link to={p_02} className={c_030}>
        <button className={c_031}>
          <div className={c_032}>{str_022}</div>
        </button>
      </Link>
      <Link to={p_01} className={c_030}>
        <button className={c_031}>
          <div className={c_032}>{str_023}</div>
        </button>
      </Link>
      <Link to={p_03} className={c_030}>
        <button className={c_031}>
          <div className={c_032}>{str_024}</div>
        </button>
      </Link>
    </div>
  );
}

export default MobileMenu;
