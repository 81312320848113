import React from "react";
import { Link } from "react-router-dom";
import { c_027, c_028, c_029 } from "../../../resources/ClassNames";
import { p_04 } from "../../../resources/Paths";
import { str_019 } from "../../../resources/Strings";
import "./../../../styles/components/header/subcomponents/HomeButton.css";

function HomeButton() {
  return (
    <Link to={p_04} className={c_027}>
      <button className={c_028}>
        <h2 className={c_029}>{str_019}</h2>
      </button>
    </Link>
  );
}

export default HomeButton;
