import React from "react";
import GithubLeetCode from "./GithubLeetCode";
import PortfolioRow from "./PortfolioRow";
import { c_051 } from "../../../resources/ClassNames";
import {
  src_022,
  src_023,
  src_024,
  src_025,
  src_026,
  src_027,
  src_028,
  src_029,
  src_030,
  src_031,
  src_032,
  src_033,
} from "../../../resources/Sources";
import {
  str_030,
  str_031,
  str_032,
  str_033,
  str_034,
  str_035,
} from "../../../resources/Strings";
import {
  alt_007,
  alt_008,
  alt_009,
  alt_010,
  alt_011,
  alt_012,
} from "../../../resources/Alternates";
import "./../../../styles/components/portfolio/subcomponents/Projects.css";

function Projects() {
  return (
    <div className={c_051}>
      <PortfolioRow
        src1={src_022}
        title1={str_030}
        tnSrc1={src_023}
        tnAlt1={alt_007}
        src2={src_024}
        title2={str_031}
        tnSrc2={src_025}
        tnAlt2={alt_008}
      />
      <PortfolioRow
        src1={src_026}
        title1={str_032}
        tnSrc1={src_027}
        tnAlt1={alt_009}
        src2={src_028}
        title2={str_033}
        tnSrc2={src_029}
        tnAlt2={alt_010}
      />
      <PortfolioRow
        src1={src_030}
        title1={str_034}
        tnSrc1={src_031}
        tnAlt1={alt_011}
        src2={src_032}
        title2={str_035}
        tnSrc2={src_033}
        tnAlt2={alt_012}
      />
      <GithubLeetCode />
      <br />
    </div>
  );
}

export default Projects;
