import React from "react";
import Cert from "./Cert";
import { c_014 } from "../../../resources/ClassNames";
import "./../../../styles/components/certs/subcomponents/CertsRow.css";
import { v_12 } from "../../../resources/Values";

function CertsRow({ src1, picSrc1, picAlt1, src2, picSrc2, picAlt2 }) {
  return (
    <div className={c_014}>
      <Cert src={src1} picSrc={picSrc1} picAlt={picAlt1} />
      {src2 !== v_12 ? (
        <Cert src={src2} picSrc={picSrc2} picAlt={picAlt2} />
      ) : null}
    </div>
  );
}

export default CertsRow;
