export const src_001 =
  "https://www.freecodecamp.org/certification/adam_reeves/full-stack";
export const src_002 = "https://i.imgur.com/cIxGAAb.jpg";
export const src_003 =
  "https://www.freecodecamp.org/certification/adam_reeves/information-security-and-quality-assurance";
export const src_004 = "https://i.imgur.com/4e9oGeI.jpg";
export const src_005 =
  "https://www.freecodecamp.org/certification/adam_reeves/javascript-algorithms-and-data-structures";
export const src_006 = "https://i.imgur.com/IbIuZhY.jpg";
export const src_007 =
  "https://www.freecodecamp.org/certification/adam_reeves/data-visualization";
export const src_008 = "https://i.imgur.com/uKIwlbs.jpg";
export const src_009 =
  "https://www.freecodecamp.org/certification/adam_reeves/front-end-libraries";
export const src_010 = "https://i.imgur.com/FObKNmw.jpg";
export const src_011 =
  "https://www.freecodecamp.org/certification/adam_reeves/responsive-web-design";
export const src_012 = "https://i.imgur.com/XlVf9fI.jpg";
export const src_013 =
  "https://www.freecodecamp.org/certification/adam_reeves/apis-and-microservices";
export const src_014 = "https://i.imgur.com/fJJCUXt.jpg";
export const src_015 = "https://www.linkedin.com/in/adam-c-reeves/";
export const src_016 = "https://www.instagram.com/adam_reeves/";
export const src_017 = "https://www.facebook.com/AdmChristopher/";
export const src_018 = "mailto:adam@adamrdev.com";
export const src_019 = "https://github.com/irish1uck";
export const src_020 = "https://leetcode.com/irish1uck/";
export const src_021 = "https://www.freecodecamp.org/adam_reeves";
export const src_022 = "https://weekendsocietymusic.com";
export const src_023 = "/weekend_society_app.png";
export const src_024 = "https://crystaloakhealing.com";
export const src_025 = "https://i.imgur.com/3qWk5kn.jpg";
export const src_026 = "https://covid-19-tracker-5b262.web.app";
export const src_027 = "https://i.imgur.com/QGUXx4b.jpg?1";
export const src_028 = "https://adams-instagram-clone.web.app";
export const src_029 = "https://i.imgur.com/Q7NzWE8.jpg";
export const src_030 = "https://clone-1e5f0.web.app";
export const src_031 = "https://i.imgur.com/wuPiexQ.jpg";
export const src_032 = "https://adams-netflix-clone-app.web.app";
export const src_033 = "https://i.imgur.com/xZFqY48.jpg";
export const src_034 = "/updatedresume.png";
