import React, { useState, useEffect } from "react";
import HomeSection from "./subcomponents/HomeSection";
import LoadingSpinner from "../utils/LoadingSpinner";
import { c_040 } from "../../resources/ClassNames";
import { v_06, v_07, v_08 } from "../../resources/Values";
import "./../../styles/components/home/HomeBody.css";

function HomeBody() {
  const [loaderVisible, setLoaderVisible] = useState(v_06);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(v_07);
    }, v_08);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loaderVisible ? (
        <LoadingSpinner visibility={loaderVisible} />
      ) : (
        <div className={c_040}>
          <HomeSection />
        </div>
      )}
    </div>
  );
}

export default HomeBody;
