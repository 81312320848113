export const alt_001 = "Full Stack Developer Certification";
export const alt_002 =
  "Information Security and Quality Assurance Certification";
export const alt_003 =
  "JavaScript Algorithms and Data Structures Certification";
export const alt_004 = "Data Visualization Certification";
export const alt_005 = "Responsive Web Design Certification";
export const alt_006 = "APIs and Microservices Certification";
export const alt_007 = "The Weekend Society Business Website";
export const alt_008 = "Crystal Oak Healing business website";
export const alt_009 = "My COVID-19 Tracker App";
export const alt_010 = "My Instagram Clone Demo App";
export const alt_011 = "My Amazon-Clone Demo App";
export const alt_012 = "My Netflix Clone Demo App";
export const alt_013 = "my resume";
