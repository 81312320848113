import React, { useState, useEffect } from "react";
import { c_018 } from "../../resources/ClassNames";
import LoadingSpinner from "../utils/LoadingSpinner";
import PageTitle from "../utils/PageTitle";
import SocialMedia from "./subcomponents/SocialMedia";
import { v_06, v_07, v_08 } from "../../resources/Values";
import { str_011 } from "../../resources/Strings";
import "./../../styles/components/contact/ContactBody.css";

function ContactBody() {
  const [loaderVisible, setLoaderVisible] = useState(v_06);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(v_07);
    }, v_08);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loaderVisible ? (
        <LoadingSpinner visibility={loaderVisible} />
      ) : (
        <div className={c_018}>
          <PageTitle title={str_011} />
          <SocialMedia />
        </div>
      )}
    </div>
  );
}

export default ContactBody;
