import React from "react";
import {
  c_041,
  c_042,
  c_043,
  c_044,
  c_045,
} from "../../../resources/ClassNames";
import { str_025, str_026, str_027 } from "../../../resources/Strings";
import "./../../../styles/components/home/subcomponents/HomeSection.css";

function HomeSection() {
  return (
    <div className={c_041}>
      <div className={c_042}>{str_025}</div>
      <div className={c_043} />
      <div className={c_044}>{str_026}</div>
      <div className={c_045}>{str_027}</div>
    </div>
  );
}

export default HomeSection;
