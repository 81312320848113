import React from "react";
import { Link } from "react-router-dom";
import { alt_013 } from "../../../resources/Alternates";
import { c_059, c_060 } from "../../../resources/ClassNames";
import { p_07 } from "../../../resources/Paths";
import { src_034 } from "../../../resources/Sources";
import "./../../../styles/components/resume/subcomponents/ResumeThumbnail.css";

function ResumeThumbnail() {
  return (
    <Link to={p_07} className={c_059}>
      <img className={c_060} src={src_034} alt={alt_013} />
    </Link>
  );
}

export default ResumeThumbnail;
