import React, { useState, useEffect } from "react";
import AboutSection from "./subcomponents/AboutSection";
import PageTitle from "../utils/PageTitle";
import LoadingSpinner from "../utils/LoadingSpinner";
import { c_009 } from "../../resources/ClassNames";
import { v_06, v_07, v_08 } from "../../resources/Values";
import {
  str_001,
  str_002,
  str_003,
  str_004,
  str_005,
  str_006,
  str_007,
  str_008,
  str_009,
} from "../../resources/Strings";
import "./../../styles/components/about/AboutBody.css";

function AboutBody() {
  const [loaderVisible, setLoaderVisible] = useState(v_06);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(v_07);
    }, v_08);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loaderVisible ? (
        <LoadingSpinner visibility={loaderVisible} />
      ) : (
        <div className={c_009}>
          <PageTitle title={str_001} />
          <AboutSection title={str_002} info={str_003} />
          <AboutSection title={str_004} info={str_005} />
          <AboutSection title={str_006} info={str_007} />
          <AboutSection title={str_008} info={str_009} />
        </div>
      )}
    </div>
  );
}

export default AboutBody;
