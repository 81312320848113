import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Portfolio from "../pages/Portfolio";
import Certifications from "../pages/Certifications";
import Resume from "../pages/Resume";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import About from "../pages/About";
import ResumePic from "../components/resume/subcomponents/ResumePic";
import { p_01, p_02, p_03, p_04, p_05, p_06, p_07 } from "../resources/Paths";
import { c_002 } from "../resources/ClassNames";
import "./../styles/app/App.css";

function App() {
  return (
    <div className={c_002}>
      <Router>
        <Switch>
          <Route exact path={p_01} component={About} />
          <Route exact path={p_02} component={Certifications} />
          <Route exact path={p_03} component={Contact} />
          <Route exact path={p_04} component={Home} />
          <Route exact path={p_05} component={Portfolio} />
          <Route exact path={p_06} component={Resume} />
          <Route exact path={p_07} component={ResumePic} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
