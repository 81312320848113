import React, { useState, useEffect } from "react";
import PageTitle from "../utils/PageTitle";
import CertsRow from "./subcomponents/CertsRow";
import LoadingSpinner from "../utils/LoadingSpinner";
import { c_013 } from "../../resources/ClassNames";
import { v_06, v_07, v_08, v_12 } from "../../resources/Values";
import { str_010 } from "../../resources/Strings";
import {
  src_001,
  src_002,
  src_003,
  src_004,
  src_005,
  src_006,
  src_007,
  src_008,
  src_009,
  src_010,
  src_011,
  src_012,
  src_013,
  src_014,
} from "../../resources/Sources";
import {
  alt_001,
  alt_002,
  alt_003,
  alt_004,
  alt_005,
  alt_006,
} from "../../resources/Alternates";
import "./../../styles/components/certs/CertsBody.css";

function CertsBody() {
  const [loaderVisible, setLoaderVisible] = useState(v_06);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaderVisible(v_07);
    }, v_08);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loaderVisible ? (
        <LoadingSpinner visibility={loaderVisible} />
      ) : (
        <div className={c_013}>
          <PageTitle title={str_010} />
          <CertsRow
            src1={src_001}
            picSrc1={src_002}
            picAlt1={alt_001}
            src2={v_12}
            picSrc2={v_12}
            picAlt2={v_12}
          />
          <CertsRow
            src1={src_003}
            picSrc1={src_004}
            picAlt1={alt_002}
            src2={src_005}
            picSrc2={src_006}
            picAlt2={alt_003}
          />
          <CertsRow
            src1={src_007}
            picSrc1={src_008}
            picAlt1={alt_004}
            src2={src_009}
            picSrc2={src_010}
            picAlt2={alt_004}
          />
          <CertsRow
            src1={src_011}
            picSrc1={src_012}
            picAlt1={alt_005}
            src2={src_013}
            picSrc2={src_014}
            picAlt2={alt_006}
          />
        </div>
      )}
    </div>
  );
}

export default CertsBody;
