import React from "react";
import { Link } from "react-router-dom";
import { c_036, c_037, c_038, c_039 } from "../../../resources/ClassNames";
import { p_01, p_02, p_03, p_05, p_06 } from "../../../resources/Paths";
import {
  str_020,
  str_021,
  str_022,
  str_023,
  str_024,
} from "../../../resources/Strings";
import "./../../../styles/components/header/subcomponents/WebMenu.css";

function WebMenu() {
  return (
    <div className={c_036}>
      <Link to={p_05} className={c_037}>
        <button className={c_038}>
          <h3 className={c_039}>{str_020}</h3>
        </button>
      </Link>
      <Link to={p_06} className={c_037}>
        <button className={c_038}>
          <h3 className={c_039}>{str_021}</h3>
        </button>
      </Link>
      <Link to={p_02} className={c_037}>
        <button className={c_038}>
          <h3 className={c_039}>{str_022}</h3>
        </button>
      </Link>
      <Link to={p_01} className={c_037}>
        <button className={c_038}>
          <h3 className={c_039}>{str_023}</h3>
        </button>
      </Link>
      <Link to={p_03} className={c_037}>
        <button className={c_038}>
          <h3 className={c_039}>{str_024}</h3>
        </button>
      </Link>
    </div>
  );
}

export default WebMenu;
