import React from "react";
import Project from "./Project";
import { c_047 } from "../../../resources/ClassNames";
import "./../../../styles/components/portfolio/subcomponents/PortfolioRow.css";

function PortfolioRow({
  src1,
  title1,
  tnSrc1,
  tnAlt1,
  src2,
  title2,
  tnSrc2,
  tnAlt2,
}) {
  return (
    <div className={c_047}>
      <Project source={src1} title={title1} tnSrc={tnSrc1} tnAlt={tnAlt1} />
      <Project source={src2} title={title2} tnSrc={tnSrc2} tnAlt={tnAlt2} />
    </div>
  );
}

export default PortfolioRow;
