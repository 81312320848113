import React from "react";
import PortfoloioBody from "../components/portfolio/PortfoloioBody";
import HeaderBody from "../components/header/HeaderBody";
import { c_007 } from "../resources/ClassNames";
import "./../styles/pages/Portfolio.css";

function Portfolio() {
  return (
    <div className={c_007}>
      <HeaderBody />
      <PortfoloioBody />
    </div>
  );
}

export default Portfolio;
