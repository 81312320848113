export const v_01 = "large";
export const v_02 = "flex";
export const v_03 = "wrap";
export const v_04 = "center";
export const v_05 = "none";
export const v_06 = true;
export const v_07 = false;
export const v_08 = 700;
export const v_09 = "Grid";
export const v_10 = "#3975A7";
export const v_11 = 100;
export const v_12 = null;
export const v_13 = 0;
export const v_14 = 1;
