import React from "react";
import { c_052, c_053, c_054, c_055 } from "../../../resources/ClassNames";
import "./../../../styles/components/portfolio/subcomponents/Project.css";

function Project({ source, title, tnSrc, tnAlt }) {
  return (
    <div className={c_052}>
      <a href={source} className={c_053}>
        <div className={c_054}>{title}</div>
        <img className={c_055} src={tnSrc} alt={tnAlt} />
      </a>
    </div>
  );
}

export default Project;
